import { useState, useRef } from 'react';

const logo = {
  alt: 'environmental engineer',
  url: 'logo01.png',
  width: 200,
  href: 'https://www.environmentalengineer.com.au/'
  
};

const menu = {
  link1:{
    copy:'home',
    url: 'https://www.environmentalengineer.com.au/'
  },
  link2:{
    copy:'about us',
    url:'#aboutus'
  },
  link3:{
    copy:'services',
    url:'#services'
  },
  link4:{
    copy:'contact',
    url: '#contact'
  },
  link5:{
    copy:'Call us today: 0458876290',
    url: 'tel:+61458876290'
  }
};


const Menu = () =>  {

  const [show, setShow] = useState(false)
  const ref = useRef(null)
  const toggle = () => ( setShow(!show))

return(
    <>
    <nav className='navbar fixed-top bg-body-tertiary shadow' role='navigation'>
    <div className='container'>
    <a className='navbar-brand' href={logo.href}><img src={logo.url} alt={logo.alt} width={logo.width} /></a>
    <div className='col d-none d-md-flex justify-content-end menu-links'>
            <a href={menu.link1.url}>{menu.link1.copy}</a> 
            <a href={menu.link2.url}>{menu.link2.copy}</a>
            <a href={menu.link3.url}>{menu.link3.copy}</a> 
            <a href={menu.link4.url}>{menu.link4.copy}</a>
            <a href={menu.link5.url}>{menu.link5.copy}</a> 
    </div>
    <button className='navbar-toggler d-md-none' type='button' aria-label='Toggle navigation'>
      <span className='navbar-toggler-icon' onClick={toggle}></span>
    </button>
    <div className='navbar-collapse flex-row-reverse'>
        {show &&  
          <div className='navbar-nav menu-links'>
            <a ref={ref} onClick={toggle} href={menu.link1.url}>{menu.link1.copy}</a> 
            <a ref={ref} onClick={toggle} href={menu.link2.url}>{menu.link2.copy}</a>
            <a ref={ref} onClick={toggle} href={menu.link3.url}>{menu.link3.copy}</a> 
            <a ref={ref} onClick={toggle} href={menu.link4.url}>{menu.link4.copy}</a> 
            <a ref={ref} onClick={toggle} href={menu.link5.url}>{menu.link5.copy}</a>
          </div>
        }
    </div>
    </div>
    </nav>
    </>
  )
}



export default Menu;